/* eslint-disable */
// @ts-nocheck
// noinspection JSUnusedGlobalSymbols
// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.
// Import Routes
import { Route as rootRoute } from './routes/__root';
import { Route as IndexImport } from './routes/index';
// Create/Update Routes
var IndexRoute = IndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: function () { return rootRoute; },
});
var rootRouteChildren = {
    IndexRoute: IndexRoute,
};
export var routeTree = rootRoute
    ._addFileChildren(rootRouteChildren)
    ._addFileTypes();
/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import './styles/globals.css';
// Import the generated route tree
import { routeTree } from './routeTree.gen';
import { ThemeProvider } from '@/providers/theme-provider';
// Create a new router instance
var router = createRouter({ routeTree: routeTree });
// Render the app
var rootElement = document.querySelector('#tutorkh_app');
if (!rootElement.innerHTML) {
    var root = ReactDOM.createRoot(rootElement);
    root.render(<StrictMode>
            <ThemeProvider>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </StrictMode>);
}

import React from 'react';
import { createRootRoute, Outlet } from '@tanstack/react-router';
import { Toaster } from '@/components/ui/sonner';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TooltipProvider } from '@/components/ui/tooltip';
var queryClient = new QueryClient();
var TanStackRouterDevtools = process.env.NODE_ENV === 'production'
    ? function () { return null; }
    : React.lazy(function () {
        return import('@tanstack/router-devtools').then(function (res) { return ({
            default: res.TanStackRouterDevtools,
        }); });
    });
export var Route = createRootRoute({
    component: function () { return (<QueryClientProvider client={queryClient}>
            <TooltipProvider>
                <Outlet />
                <Toaster position="top-right" richColors toastOptions={{}}/>
                <TanStackRouterDevtools />
            </TooltipProvider>
        </QueryClientProvider>); },
});
